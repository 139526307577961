import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-3px" }}
      width="162" height="9" viewBox="0 0 162 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.0064 8.33102C26.6756 7.42759 2.25448 8.25375 0.474467 7.99818C-0.981903 7.64156 1.2566 1.47805 2.59161 0.568684C3.06358 0.24773 3.61644 0.18235 4.88402 0.354714C5.89539 0.485473 12.8536 0.467639 19.5691 0.402259C26.2846 0.33688 32.7573 0.259614 32.7573 0.259614C33.0945 0.455753 34.1328 -0.180211 34.3216 0.0515895C34.6183 0.324995 45.2444 0.372544 56.248 0.372544C67.2517 0.372544 78.633 0.354711 80.413 0.360654C82.5841 0.366598 80.8445 0.717274 83.4067 0.610289C88.8546 0.384432 100.694 0.883691 104.821 0.616229C108.138 0.40226 108.839 0.414147 111.995 0.681609C115.204 0.955015 132.411 0.996621 134.407 0.622174C134.892 0.515189 135.256 0.562737 135.256 0.699439C135.485 0.71727 147.352 1.11549 147.393 1.21653C147.474 1.35324 147.838 1.4305 148.188 1.34135C148.835 1.21059 161.768 1.01445 161.929 1.41862C162.428 2.57762 160.176 7.38004 158.599 8.5331C156.94 9.75154 148.728 8.12894 141.931 8.73518C104.861 8.00412 112.237 8.06356 103.863 8.50338C103.122 8.07544 100.128 9.04425 98.3479 8.29536C97.6198 7.98629 92.2932 7.86742 91.0796 8.14677C89.7446 8.45584 63.0579 7.30277 59.9294 8.17054C54.967 7.40976 34.6992 8.19432 30.0064 8.33102Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg  style={{ bottom: "-3px" }} width="267" height="9" viewBox="0 0 267 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49.455 8.33102C43.9654 7.42759 3.71571 8.25375 0.781992 7.99818C-1.61832 7.64156 2.07107 1.47805 4.27135 0.568684C5.04923 0.24773 5.96042 0.18235 8.04959 0.354714C9.71647 0.485473 21.1847 0.467639 32.2528 0.402259C43.3209 0.33688 53.989 0.259614 53.989 0.259614C54.5446 0.455753 56.2559 -0.180211 56.5671 0.0515895C57.056 0.324995 74.5694 0.372544 92.7051 0.372544C110.841 0.372544 129.599 0.354711 132.533 0.360654C136.111 0.366598 133.244 0.717274 137.467 0.610289C146.446 0.384432 165.959 0.883691 172.76 0.616229C178.227 0.40226 179.383 0.414147 184.584 0.681609C189.873 0.955015 218.233 0.996621 221.522 0.622174C222.322 0.515189 222.922 0.562737 222.922 0.699439C223.3 0.71727 242.858 1.11549 242.925 1.21653C243.058 1.35324 243.658 1.4305 244.236 1.34135C245.303 1.21059 266.617 1.01445 266.884 1.41862C267.706 2.57762 263.994 7.38004 261.394 8.5331C258.66 9.75154 245.125 8.12894 233.924 8.73518C172.827 8.00412 184.984 8.06356 171.182 8.50338C169.96 8.07544 165.026 9.04425 162.092 8.29536C160.892 7.98629 152.113 7.86742 150.113 8.14677C147.912 8.45584 103.929 7.30277 98.7726 8.17054C90.5937 7.40976 57.1894 8.19432 49.455 8.33102Z" fill="#FFBA00" />
    </svg>
  </span>
)