import React from "react"

export const TitleUnderlineTestimonyOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="224" height="10" viewBox="0 0 224 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.5449 8.72465C36.3662 7.97829 2.69941 10.1013 0.770434 9.99622C-1.27257 9.75296 1.19804 3.89821 2.96547 2.96941C4.04874 2.4055 3.97272 2.81461 13.3135 2.4055C13.3135 2.4055 27.396 1.80842 44.7092 1.11182C44.7092 1.11182 72.5225 0.210661 110.817 0.000575624C113.829 -0.0160101 111.444 0.332289 114.988 0.210661C122.543 -0.0381242 138.982 0.431803 144.702 0.232775C149.292 0.072447 150.28 0.0613902 154.661 0.365461C157.673 0.575546 182.949 1.09523 185.752 0.780103C186.436 0.702703 186.931 0.75246 186.921 0.879617C187.121 0.890674 203.683 1.64809 203.759 1.76419C203.873 1.89687 204.377 1.9798 204.861 1.91346C205.755 1.7863 223.667 2.17883 223.904 2.56583C224.588 3.67154 221.462 8.0391 219.267 9.05083C216.996 10.0957 205.983 8.26025 196.252 8.57538C150.869 6.94445 151.221 7.35909 143.638 7.57471C142.593 7.17112 138.469 8.03911 136.017 7.34803C134.991 7.05502 127.617 6.94445 125.964 7.19324C125.612 7.24852 122.98 7.23746 119.179 7.19876C117.278 7.18218 115.083 7.15453 112.736 7.13242C109.601 7.09925 85.8733 6.94998 82.8991 7.59129C74.8981 6.94998 49.1563 8.33765 41.5449 8.72465Z" fill="#FFBA00" />
    </svg>
  </span>
)


export const TitleUnderlineTestimonyTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="245" height="10" viewBox="0 0 245 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45.4398 8.72465C39.7755 7.97829 2.95248 10.1013 0.842662 9.99622C-1.39187 9.75296 1.31036 3.89821 3.24349 2.96941C4.42831 2.4055 4.34516 2.81461 14.5616 2.4055C14.5616 2.4055 29.9643 1.80842 48.9007 1.11182C48.9007 1.11182 79.3215 0.210661 121.206 0.000575624C124.501 -0.0160101 121.892 0.332289 125.769 0.210661C134.031 -0.0381242 152.011 0.431803 158.268 0.232775C163.288 0.072447 164.369 0.0613902 169.16 0.365461C172.455 0.575546 200.101 1.09523 203.167 0.780103C203.915 0.702703 204.455 0.75246 204.445 0.879617C204.663 0.890674 222.778 1.64809 222.862 1.76419C222.986 1.89687 223.537 1.9798 224.067 1.91346C225.044 1.7863 244.635 2.17883 244.895 2.56583C245.643 3.67154 242.224 8.0391 239.823 9.05083C237.339 10.0957 225.294 8.26025 214.651 8.57538C165.013 6.94445 165.398 7.35909 157.104 7.57471C155.961 7.17112 151.45 8.03911 148.769 7.34803C147.646 7.05502 139.581 6.94445 137.773 7.19324C137.388 7.24852 134.509 7.23746 130.352 7.19876C128.273 7.18218 125.873 7.15453 123.305 7.13242C119.876 7.09925 93.9239 6.94998 90.6708 7.59129C81.9198 6.94998 53.7647 8.33765 45.4398 8.72465Z" fill="#FFBA00" />
    </svg>
  </span>
)
