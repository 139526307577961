import React from "react"

export const TitleUnderlineNavbarVerticalOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="333" height="11" viewBox="0 0 333 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M62.0771 9.37292C54.0951 8.58671 5.04701 10.4121 1.54693 10.2509C-1.5586 9.98053 0.621928 4.11424 3.03453 3.20219C3.88991 2.87802 5.00638 2.79555 7.67533 2.89574C10.0161 2.98275 26.1454 2.58512 65.1658 1.71425C65.9306 1.88647 67.8601 1.25622 68.3182 1.47123C68.6676 1.59622 74.3351 1.56971 82.76 1.49316C92.2134 1.4062 117.948 1.08448 130.64 0.967214C149.372 0.786632 142.326 0.804662 163.771 0.674291C168.257 0.646079 164.754 1.0042 170.028 0.869168C181.277 0.586666 205.87 0.950303 214.383 0.690042C221.212 0.480824 222.7 0.47323 229.263 0.731414C234.513 0.936845 271.558 1.08682 275.739 0.755206C276.746 0.655317 277.512 0.710279 277.489 0.838567C277.927 0.852429 302.584 1.34489 302.693 1.45166C302.867 1.58116 303.611 1.65832 304.333 1.57901C305.647 1.44192 332.452 1.48974 332.822 1.87172C333.865 2.97256 329.308 7.44502 326.085 8.50284C322.708 9.60997 306.088 7.96091 291.819 8.44816C291.819 8.44816 287.793 8.39545 281.754 8.308C222.788 7.49817 224.799 7.81766 213.55 8.10574C211.955 7.70506 205.932 8.62838 202.194 7.94089C200.642 7.65773 189.68 7.59021 187.228 7.8543C186.702 7.91248 182.807 7.91084 177.14 7.89269C171.495 7.87467 164.077 7.83457 156.572 7.84419C152.808 7.84893 149.022 7.84796 145.434 7.86494C141.823 7.87621 138.387 7.91647 135.346 7.95916C129.24 8.0444 124.688 8.16713 123.286 8.37068C112.152 7.7549 72.7136 9.04756 62.0771 9.37292Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineNavbarVerticalTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="97" height="12" viewBox="0 0 97 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9054 10.1009C15.607 9.34968 1.45476 11.3899 0.446131 11.2441C-0.448244 10.9874 0.206277 5.11168 0.90601 4.18908C1.1541 3.86117 1.47643 3.77381 2.24565 3.8623C2.9203 3.93905 7.57333 3.47075 18.8297 2.4289C19.0495 2.59776 19.6087 1.95907 19.7398 2.17206C19.84 2.29552 21.4745 2.24417 23.9044 2.13071C26.6309 2.00232 34.0536 1.56783 37.7142 1.39494C43.1168 1.13227 41.0849 1.18118 47.2696 0.956827C48.5634 0.908957 47.5515 1.28242 49.0731 1.12428C52.3183 0.792486 59.4086 1.04834 61.8649 0.750772C63.8349 0.511634 64.2641 0.497519 66.1555 0.726935C67.6687 0.90935 78.3509 0.89697 79.5582 0.547044C79.849 0.442743 80.0696 0.49435 80.0625 0.622733C80.1886 0.634677 87.2971 1.01907 87.328 1.12535C87.3776 1.25408 87.5917 1.32799 87.8004 1.24551C88.1799 1.10267 95.9095 1.03302 96.0145 1.41336C96.3106 2.50961 94.9768 7.00191 94.0427 8.07383C93.0641 9.19573 88.2786 7.61955 84.1614 8.16932C84.1614 8.16932 83.0006 8.13426 81.2596 8.07328C64.2587 7.52188 64.8372 7.83255 61.5921 8.16993C61.1339 7.77624 59.3929 8.72593 58.3181 8.05484C57.8718 7.77849 54.7109 7.75902 54.0025 8.03384C53.8507 8.09433 52.7275 8.10975 51.0933 8.11644C49.4654 8.12317 47.3265 8.11558 45.162 8.15809C44.0767 8.17932 42.985 8.19493 41.9501 8.22765C40.9088 8.25474 39.9179 8.31006 39.0405 8.36607C37.2794 8.47807 35.9662 8.62074 35.5611 8.83043C32.353 8.26347 20.9741 9.72893 17.9054 10.1009Z" fill="#FFBA00" />
    </svg>
  </span>
)
